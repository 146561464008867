import React, { useState, useEffect } from "react";
import { ReactComponent as Logo } from "./images/logo.svg";
import "./App.css";
import TextInputField from "./components/TextInputField";
import StarRating from "./components/StarRating";

function App() {
  const [rating, setRating] = useState<number>(0);
  const [feedback, setFeedback] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isUnsubscribing, setIsUnsubscribing] = useState<boolean>(false);
  const [action, setAction] = useState<string | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const initialRating = parseInt(params.get("rating") || "0", 10);
    const tokenParam = params.get("token");
    const actionParam = params.get("action");

    if (initialRating > 0 && initialRating <= 5) {
      setRating(initialRating);
    }

    if (tokenParam) {
      setToken(tokenParam);
    } else {
      setError("Oops, something went wrong.");
    }

    if (actionParam) {
      setAction(actionParam);
      if (actionParam === "unsubscribe" && tokenParam) {
        handleUnsubscribe(tokenParam);
      }
    }
  }, []);

  const handleRatingChange = (newRating: number) => {
    setRating(newRating);
  };

  const handleFeedbackChange = (newFeedback: string) => {
    setFeedback(newFeedback);
  };

  const handleSubmit = async () => {
    if (rating > 0 && token) {
      setIsSubmitting(true);
      const feedbackData = {
        token: token,
        feedbackRating: rating,
        feedbackText: feedback,
      };

      try {
        const response = await fetch(
          "https://europe-west1-hardware-provisioning.cloudfunctions.net/submit_feedback",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(feedbackData),
          }
        );

        if (response.ok) {
          setSubmitted(true);
        } else {
          setError("Oops, something went wrong.");
        }
      } catch (error: any) {
        setError("Oops, something went wrong.");
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setError("Oops, something went wrong.");
    }
  };

  const handleUnsubscribe = async (token: string) => {
    setIsUnsubscribing(true);
    try {
      const response = await fetch(
        "https://europe-west1-hardware-provisioning.cloudfunctions.net/unsubscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
        }
      );

      if (response.ok) {
        setSubmitted(true);
      } else {
        setError("Oops, something went wrong.");
      }
    } catch (error: any) {
      setError("Oops, something went wrong.");
    }
  };

  return (
    <div className="App">
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap"
          rel="stylesheet"
        />
      </head>
      <div className="App-header">
        <div className="App-body">
          <div className="logo">
            <Logo />
          </div>
          <div className="App-body-int">
            {error ? (
              <h2>{error}</h2>
            ) : submitted ? (
              <h2>
                {isUnsubscribing
                  ? "You have been unsubscribed."
                  : "Thanks for submitting the feedback!"}
              </h2>
            ) : action === "unsubscribe" ? (
              <h2>Processing your unsubscribe request...</h2>
            ) : (
              <>
                <h1>We value your feedback</h1>
                <div className="Text">
                  Rate your experience. Your insights help us improve.
                </div>
                <StarRating rating={rating} onRate={handleRatingChange} />
                <TextInputField
                  label=""
                  placeholder="Share your thoughts about the service..."
                  onChange={handleFeedbackChange}
                />
                <div className="Buttons">
                  <div
                    className={`Invite-Link ${
                      rating === 0 || isSubmitting ? "disabled" : ""
                    }`}
                    onClick={handleSubmit}
                    style={{ pointerEvents: isSubmitting ? "none" : "auto" }}
                  >
                    Submit
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="Links">
            <a
              href="https://realworld-one.com/imprint/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Imprint
            </a>
            <a
              href="https://realworld-one.com/privacy-notice/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Notice
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
